// -----------------------------------------------------------------------------
// Typography
// -----------------------------------------------------------------------------

//
// Default Typography Settings
//
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: $title-bottom-space;
}

p {
  font-family: "Open Sans", serif;
  margin-top: 0;
  margin-bottom: $paragraph-bottom-space;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: $list-bottom-space;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

a {
  text-decoration: none;
}
