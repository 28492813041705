// -----------------------------------------------------------------------------
// Breakpoints
// -----------------------------------------------------------------------------

$breakpoints: (
    xs: 375px,
    sm: 576px,
    md: 640px,
    lg: 992px,
    xl: 1200px,
    xxl: 1366px,
    xxxl: 1600px
) !default;

@mixin respond-to-up($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
            @content;
        }
    } @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Please make sure it is defined in `$breakpoints` map.";
    }
}

@mixin respond-to-down($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
            @content;
        }
    } @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Please make sure it is defined in `$breakpoints` map.";
    }
}
