.c-about-us {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    @include respond-to-up('xl') {
        flex-direction: row;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;

        p {
            font-weight: normal;
            letter-spacing: -0.02em;
            color: #272525;
            text-align: center;
            font-size: 14px;
            
            @include respond-to-up('xl') {
                font-size: 16px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &__title {
        font-weight: normal;
        text-align: center;
        color: #272525;
        margin-bottom: 10px;
        font-size: 28px;
        position: relative;

        @include respond-to-up('xl') {
            font-size: 40px;
        }

        &::before, &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            height: 0.1px;
            background-color: #272525;
            width: 25px;
            
            @include respond-to-up('xl') {
                width: 50px;
            }
        }

        &::before {
            left: -35px;

            @include respond-to-up('xl') {
                left: -65px;
            }
        }

        &::after {
            right: -35px;
            
            @include respond-to-up('xl') {
                right: -65px;
            }
        }
    }

    &__image {
        height: 400px;
        margin-left: -200px;

        img {
            height: 100%;
        }

        @include respond-to-up('xl') {
            height: 500px;
            margin-left: -200px;
        }

        @include respond-to-up('xxl') {
            margin-left: -300px;
            height: 600px;
        }
    }
}