.o-footer {
    margin-top: 100px;
    background-color: #272525;
    padding: 25px 0 30px 0;

    @include respond-to-up('xl') {
        padding: 150px 0 50px 0;
    }

    &__top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // flex-direction: column-reverse;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 35px;
        gap: 40px;

        @include respond-to-up('lg') {
            margin-bottom: 50px;
        }

        @include respond-to-up('xl') {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__left {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 40px 0;
        width: 100%;

        >* {
            width: 50%;

            @include respond-to-up('md') {
                width: auto;
            }
        }

        @include respond-to-up('xl') {
            width: auto;
            gap: 0;
            justify-content: initial;
            flex-direction: row;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        gap: 18px;
        width: 100%;

        @include respond-to-up('xl') {
            width: auto;
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 98px;
        height: 103px;
        border-radius: 19px;
        background-color: $white;
    }

    &__nav {
        @include respond-to-up('lg') {
            flex: 1;
            width: auto;
        }
    }

    &__list {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        gap: 20px;

        @include respond-to-up('lg') {
            gap: 5px;
            justify-content: space-evenly;
        }

        @include respond-to-up('xl') {
            gap: 15px;
        }

        &-item {
            a {
                font-weight: normal;
                font-size: 14px;
                letter-spacing: -0.02em;
                text-align: left;
                color: $white;
                transition: all ease-in-out .3s;

                &:hover {
                    opacity: .8;
                }

                @include respond-to-up('xl') {
                    font-size: 16px;
                }
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 25px;

        @include respond-to-up('xl') {
            gap: 35px;
        }
    }

    &__contact {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &-title {
            font-weight: normal;
            font-size: 14px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #fff;
            margin-bottom: 0;

            @include respond-to-up('xl') {
                font-size: 16px;
            }
        }

        &-info {
            display: flex;
            flex-direction: column;

            span,
            a {
                font-family: "Open Sans";
                font-weight: normal;
                font-size: 14px;
                letter-spacing: -0.02em;
                text-align: left;
                color: #fff;

                @include respond-to-up('xl') {
                    font-size: 16px;
                }
            }
        }
    }

    &__social {
        display: flex;
        flex-direction: column;

        @include respond-to-up('lg') {
            align-items: center;
            justify-content: center;
        }

        &-title {
            font-weight: normal;
            font-size: 14px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #fff;
            margin-bottom: 8px;

            @include respond-to-up('xl') {
                font-size: 16px;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 53px;
            height: 53px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.1);
            transition: all ease-in-out .3s;

            &:hover {
                opacity: .7;
            }
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 18px;

        @include respond-to-up('xl') {
            align-items: flex-start;
        }

        .c-button {
            width: 100%;

            @include respond-to-up('md') {
                width: 270px;
            }
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        gap: 50px;

        @include respond-to-up('lg') {
            gap: 0;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
        }
    }
}