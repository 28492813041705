.c-sticky-menu {
    position: fixed;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000;
    bottom: 40px;

    &__item {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        background: #25D366;
        position: relative;
        align-self: flex-end;

        svg {
            width: 18px;
            height: 18px;
            fill: white;
            transition: margin-right 0.3s ease-in-out;
        }

        &--whatsapp {
            background: #25D366;
        }

        &--instagram {
            background: #C13584;
        }
    }

    &__item--phone {
        background: #128C7E;
        width: 38px;
        border-radius: 50px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out;

        svg {
            position: absolute;
            left: 10px;
            transition: left 0.3s ease-in-out;
        }

        span {
            color: white;
            font-size: 12px;
            font-weight: normal;
            opacity: 0;
            margin-left: 30px;
            transition: opacity 0.3s ease-in-out;
            white-space: nowrap;
        }

        &:hover {
            width: 140px;
            border-radius: 30px;
            justify-content: flex-start;
            padding-left: 15px;

            svg {
                left: 15px;
            }

            span {
                opacity: 1;
            }
        }
    }
}
