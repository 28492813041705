$max: 200;
$offset: 5;
$unit: 'px';

@mixin list-loop($className, $styleName) {
    $i: 0;

    @while $i <= $max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit} !important;
        }

        $i: $i + $offset;
    }
}

// Margins
@include list-loop('.mt-', 'margin-top');
@include list-loop('.mr-', 'margin-right');
@include list-loop('.mb-', 'margin-bottom');
@include list-loop('.ml-', 'margin-left');
@include list-loop('.mx-', 'margin');

// Paddings
@include list-loop('.pt-', 'padding-top');
@include list-loop('.pr-', 'padding-right');
@include list-loop('.pb-', 'padding-bottom');
@include list-loop('.pl-', 'padding-left');
@include list-loop('.px-', 'padding');