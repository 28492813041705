.c-orders {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.c-order {
    border-radius: 20px;
    background-color: #fff;
    padding: 33px 33px 33px 45px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @include respond-to-up('md') {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 25px;

        @include respond-to-up('md') {
            flex-direction: row;
        }
    }

    &__image {
        width: 128px;
        height: 128px;
        border-radius: 20px;
        background-color: #faf8f8;
        border: 1px solid #a7a7a7;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
            mix-blend-mode: multiply;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-family: "Open Sans";

        span {
            font-weight: normal;
            font-size: 14px;
            color: #a7a7a7;

            &[data-label] {
                &::before {
                    content: attr(data-label);
                }
            }

            &.red {
                font-weight: bold;
                color: #f2405b;
            }
        }
    }

    &__title {
        font-weight: normal;
        font-size: 16px;
        text-align: left;
        color: #272525;
        margin-bottom: 0;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @include respond-to-up('md') {
            gap: 15px;
        }
    }

    &__action {
        display: flex;
        align-items: center;
        gap: 8px;

        @include respond-to-up('md') {
            align-self: flex-end;
        }

        svg {
            fill: #a7a7a7;
            transition: all ease-in-out .3s;
            width: 21px;
        }

        span {
            font-weight: normal;
            font-size: 14px;
            color: #a7a7a7;
            transition: all ease-in-out .3s;

            @include respond-to-up('md') {
                text-align: right;
            }
        }

        &:hover {
            svg {
                fill: #000;
            }

            span {
                color: #000;
            }
        }
    }
}