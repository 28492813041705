.o-section {
    margin-bottom: 50px;

    @include respond-to-up('xl') {
        margin-bottom: 75px;
    }

    @include respond-to-up('xxl') {
        margin-bottom: 125px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__content {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        >*:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        font-weight: normal;
        text-align: center;
        color: #272525;
        margin-bottom: 10px;
        font-size: 28px;
        position: relative;

        >*:last-child {
            margin-bottom: 0;
        }

        @include respond-to-up('xl') {
            font-size: 40px;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            height: 0.1px;
            background-color: #272525;
            width: 25px;

            @include respond-to-up('xl') {
                width: 50px;
            }
        }

        &::before {
            left: -35px;

            @include respond-to-up('xl') {
                left: -65px;
            }
        }

        &::after {
            right: -35px;

            @include respond-to-up('xl') {
                right: -65px;
            }
        }
    }
}