// -----------------------------------------------------------------------------
// Reboot
// -----------------------------------------------------------------------------

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-size: $font-size-root;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: 300;
  line-height: $line-height-base;
  overflow-x: hidden;
  max-width: 1920px;
  margin: 0 auto;
  background-color: #F2F2F2;
}

main {
  overflow: hidden;

  >*:last-child {
    margin-bottom: 0;
  }
}

.main-wrapper--subpage {
  padding-top: 130px;

  @include respond-to-up('xl') {
    padding-top: 150px;
  }

  @include respond-to-up('xxxl') {
    padding-top: 190px;
  }
}

.leaflet-bottom.leaflet-right {
  display: none !important;
}