// -----------------------------------------------------------------------------
// Icons
// -----------------------------------------------------------------------------
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 0;
}
