.c-vision-mission {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    picture {
        width: 100%;
    }

    &__bg {
        width: 100%;
        height: 100%;
        display: flex;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__container {
        position: absolute;
        z-index: 1;
    }

    &__items {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include respond-to-up('xl') {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        @include respond-to-up('xl') {
            gap: 20px;
        }

        &-image {
            background-color: rgba(0, 0, 0, 0.7);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 73px;
            width: 156px;
            height: 156px;
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);

            @include respond-to-up('xxl') {
                width: 215px;
                height: 215px;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        span {
            font-weight: normal;
            text-align: center;
            color: #fff;
            font-size: 16px;
            text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);

            @include respond-to-up('xl') {
                font-size: 24px;
            }
        }
    }
}