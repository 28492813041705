.c-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__items {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;
        width: 100%;

        @include respond-to-up('xl') {
            flex-direction: row;
        }
    }

    &__item {
        border-radius: 16px;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        width: 100%;
        position: relative;
        padding: 85px 16px 30px 16px;
        max-width: 375px;

        @include respond-to-up('xl') {
            padding: 16px;
            max-width: none;
            height: 475px;
        }

        &--new {
            &::before {
                content: 'Yeni';
                position: absolute;
                left: 16px;
                top: 16px;
                border: 1px solid #3000B2;
                padding: 7px 15px;
                color: #3000B2;
                font-weight: normal;
                font-size: 16px;
                text-align: center;
                color: #3000b2;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        @include respond-to-up('xl') {
            width: calc(100% / 3 - 20px);
        }

        @include respond-to-up('xxl') {
            max-width: 370px;
            height: 530px;
        }
    }

    &__image {
        margin-bottom: 50px;
        height: 180px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        >*:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        font-weight: normal;
        text-align: center;
        color: #a7a7a7;
        margin-bottom: 4px;
        font-size: 16px;
    }

    &__price {
        font-weight: normal;
        font-size: 24px;
        text-align: center;
        color: #272525;
        
        @include respond-to-up('xl') {
            font-size: 30px;
        }
    }

    &__favorite {
        content: url('/assets/images/SVG/sharp-favorite.svg');
        position: absolute;
        right: 16px;
        top: 16px;

        &.active,
        &:hover {
            content: url('/assets/images/SVG/sharp-favorite-active.svg');
            cursor: pointer;
        }
    }
}