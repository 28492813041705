.c-accordion {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;

    @include respond-to-up('xl') {
        margin-top: 100px;
    }

    &__item {
        border-bottom: 1px solid #272525;
        padding-bottom: 30px;

        &.active {
            padding-bottom: 0;

            .c-accordion__head {
                &::after {
                    content: url('/assets/images/SVG/accordion-minus.svg');
                }

                &-title {
                    font-weight: bold;
                }
            }

            .c-accordion__body {
                height: auto;
                opacity: 1;
            }

            .c-accordion__content {
                display: block;
            }
        }
    }

    &__head {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            font-family: "Open Sans";
            font-weight: 500;
            letter-spacing: -0.02em;
            text-align: left;
            color: #272525;
            font-size: 16px;

                @include respond-to-up('xl') {
                    font-size: 18px;
                }
        }

        &::after {
            content: url('/assets/images/SVG/accordion-plus.svg');
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__body {
        opacity: 0;
        height: 0;
        transition: all ease-in-out .3s;
    }

    &__content {
        display: none;
        padding: 15px;

        @include respond-to-up('xl') {
            padding: 30px 20px;
        }

        p,
        li {
            font-family: "Open Sans";
            font-weight: normal;
            letter-spacing: -0.02em;
            text-align: left;
            color: #4e4e4e;
            font-size: 14px;

            @include respond-to-up('xl') {
                font-size: 16px;
            }
        }

        ul {
            &.checklist {
                list-style: none;
                padding: 0;
                margin-bottom: 0;

                li {
                    position: relative;
                    padding-left: 30px;
                    margin-bottom: 15px;
                    color: #4e4e4e;
                    font-size: 14px;

                    &::before {
                        content: "✔";
                        color: #27ae60;
                        font-size: 20px;
                        font-weight: bold;
                        position: absolute;
                        left: 0;
                        top: 3px;
                    }

                    strong {
                        color: #000;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}