.o-header {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    width: 100%;
    height: 120px;
    display: none;
    align-items: center;
    transition: all ease-in-out .3s;

    @include respond-to-up('xl') {
        display: flex;
    }

    &--gray {
        background-color: #f2f2f2;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);

        .o-header__logo {
            width: 89px;
            height: 92px;
            background-color: unset;
        }

        .o-header__list-item {
            a {
                color: #272525;
            }
        }

        .o-header__search {
            background-color: rgba(0, 0, 0, 0.05);

            input {
                color: rgba(39, 37, 37, 0.7);

                &::placeholder {
                    color: rgba(39, 37, 37, 0.7);
                }
            }

            &-icon {
                svg {
                    fill: #000000;
                }
            }
        }

        .o-header__action {
            background-color: rgba(112, 112, 112, 0.05);

            svg {
                fill: #000000;
            }
        }
    }

    &__container {
        max-width: 1485px;
        padding: 0 35px;
        width: 100%;
        margin: 0 auto;
        display: flex;
    }

    &__nav {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
        flex: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__logo {
        width: 57px;
        height: 59px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        padding: 3px;
        border-radius: 19px;

        img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        gap: 40px;

        a {
            font-weight: 400;
            font-size: 16px;
            letter-spacing: -0.02em;
            color: $white;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    &__search {
        display: flex;
        align-items: center;
        height: 47px;
        gap: 10px;
        padding: 0 17.5px 0 21px;
        border-radius: 23.5px;
        background-color: rgba(0, 0, 0, 0.35);
        width: 315px;

        input {
            flex: 1;
            width: 100%;
            height: 100%;
            padding: 0;
            background: none;
            border: none;
            outline: none;
            font-family: "Open Sans", serif;
            font-weight: normal;
            font-size: 16px;
            letter-spacing: -0.02em;
            color: rgba(255, 255, 255, 0.7);

            &::placeholder {
                font-family: "Open Sans", serif;
                font-weight: normal;
                font-size: 16px;
                letter-spacing: -0.02em;
                color: rgba(255, 255, 255, 0.7);
            }
        }

        &-icon {
            cursor: pointer;
            background: none;
            border: none;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                fill: $white;
            }
        }
    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 47px;
        height: 47px;
        background-color: rgba(0, 0, 0, 0.35);
        border-radius: 50%;
        padding: 11px;

        svg {
            fill: $white;
        }
    }
}