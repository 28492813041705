.c-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 0 30px;
    transition: all ease-in-out .3s;
    border: 2px solid;
    
    @include respond-to-up('xl') {
        height: 60px;
        padding: 0;
        min-width: 175px;
    }

    span {
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        color: #272525;
        transition: all ease-in-out .3s;

        @include respond-to-up('xl') {
            font-size: 16px;
        }
    }

    svg {
        transition: all ease-in-out .3s;
    }

    &:hover {
        cursor: pointer;
    }

    &--md-fix {
        width: 145px;
        padding: 0;
    }

    &--md {
        min-width: 135px;
    }

    &--white {
        background-color: $white;
        border-color: $white;

        &:hover {
            background-color: transparent;

            span {
                color: $white;
            }
        }
    }

    &--white-transparent {
        background-color: transparent;
        border-color: $white;

        span {
            color: $white;
        }

        &:hover {
            background-color: $white;

            span {
                color: #272525;
            }
        }
    }

    &--red {
        background-color: #f2405b;
        border-color: #f2405b;

        span {
            color: $white;
        }

        svg {
            fill: $white;
        }

        &:hover {
            background-color: transparent;

            span {
                color: #f2405b;
            }

            svg {
                fill: #f2405b;
            }
        }
    }

    &--transparent-black {
        background-color: transparent;
        border-color: #707070;

        span {
            color: #272525;
        }

        svg {
            fill: #272525;
        }

        &:hover {
            border-color: #272525;
            background-color: #272525;

            svg {
                fill: $white;
            }

            span {
                color: $white;
            }
        }
    }
}