.c-gallery {
    &__item {
        border-radius: 32px;
        height: 210px;
        overflow: hidden;

        img {
            width: 100%;
            border-radius: 32px;
            height: 100%;
            object-fit: cover;
            transition: all ease-in-out .3s;
        }

        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
}