.u-is-desktop {
    display: none;
    width: 100%;

    @include respond-to-up('lg') {
        display: block;
        width: auto;
    }
}

.u-is-mobile {
    display: block;
    width: 100%;

    @include respond-to-up('lg') {
        display: none;
    }
}