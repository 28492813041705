article {
    > :last-child {
        margin-bottom: 0 !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600;

        span {
            color: $primary;
        }
    }

    h1:not(:first-child),
    h2:not(:first-child),
    h3:not(:first-child),
    h4:not(:first-child),
    h5:not(:first-child),
    h6:not(:first-child) {
        margin-top: 15px;

        @include respond-to-up('lg') {
            margin-top: 20px;
        }
    }

    h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 20px;

        @include respond-to-up('lg') {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 15px;
        }
    }

    h2 {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;

        @include respond-to-up('lg') {
            font-size: 32px;
            line-height: 38px;
            margin-bottom: 15px;
        }
    }

    h3 {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;

        @include respond-to-up('lg') {
            font-size: 28px;
            line-height: 33px;
            margin-bottom: 15px;
        }
    }

    h4 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;

        @include respond-to-up('lg') {
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 15px;
        }
    }

    h5 {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;

        @include respond-to-up('lg') {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 15px;
        }
    }

    h6 {
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 10px;

        @include respond-to-up('lg') {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 15px;
        }
    }

    p {
        font-weight: 300;
        font-size: 14px;
        line-height: 23px;
        letter-spacing: 0.01em;
        margin-bottom: 15px;
        font-family: 'Helvetica Neue', 'Inter';
        color: #2F2F2F;

        @include respond-to-up('xl') {
            font-size: 18px;
            line-height: 32px;
            margin-bottom: 30px;
        }
        
        @include respond-to-up('xxl') {
            font-size: 20px;
            line-height: 36px;
        }

        span {
            font-weight: 600;
        }

        &:has(+ img) {
            margin-bottom: 0;
        }
    }

    ul,
    ol {
        margin-bottom: 15px;
        padding-left: 30px;

        @include respond-to-up('lg') {
            margin-bottom: 20px;
        }

        li {
            font-size: 14px;
            line-height: 23px;
            letter-spacing: 0.01em;
            font-weight: 400;

            @include respond-to-up('lg') {
                font-size: 16px;
                line-height: 28px;
            }

            p {
                margin-bottom: 0 !important;
            }
        }

        &:not(:first-child) {
            margin-top: 20px;
        }
    }

    blockquote {
        margin-inline-start: 20px;
        margin-inline-end: 20px;

        @include respond-to-up('lg') {
            margin-inline-start: 40px;
            margin-inline-end: 40px;
        }
    }

    a {
        text-decoration: none;
        color: $primary;
    }

    > img {
        width: 100%;
        object-fit: cover;
        margin: 35px 0;

        &:has(+ *) {
            margin: 35px 0;
        }

        @include respond-to-up("xl") {
            margin: 77px 0;

            &:has(+ *) {
                margin: 77px 0;
            }
        }
    }
}