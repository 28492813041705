.o-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include respond-to-up('md') {
        max-width: 50%;
        margin: 0 auto;
    }

    @include respond-to-up('xl') {
        margin: auto;
        max-width: none;
    }

    &__group,
    &__wrapper {
        width: 100%;
    }

    &__group {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 33px;

        p {
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: -0.02em;
            text-align: center;
            color: #4e4e4e;
            margin-bottom: 0;

            @include respond-to-up('xl') {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &--row {
            @include respond-to-up('lg') {
                flex-direction: row;
            }
        }
    }

    &__item {
        flex: 1;

        &--has-label {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        label {
            font-family: "Open Sans";
            font-size: 16px;
            line-height: 22px;
            color: #272525;
            font-weight: 600;
        }

        input,
        textarea {
            padding: 0 20px;
            border: 1px solid #a7a7a7;
            outline: none;
            background: #F2F2F2;
            width: 100%;
            font-family: "Open Sans";
            font-weight: normal;
            letter-spacing: -0.02em;
            height: 48px;
            color: #000000;
            font-size: 14px;
            transition: all ease-in-out .2s;

            @include respond-to-up('xl') {
                font-size: 16px;
            }

            &:read-only {
                background-color: #E2E2E2;
                border-color: #E2E2E2;
                pointer-events: none;
                user-select: none;
            }

            &::placeholder {
                font-family: "Open Sans";
                font-weight: normal;
                letter-spacing: -0.02em;
                color: #a7a7a7;
                transition: all ease-in-out .2s;
                font-size: 14px;

                @include respond-to-up('xl') {
                    font-size: 16px;
                }
            }

            &:focus {
                &:not(:read-only) {
                    border-color: #272525;

                    &::placeholder {
                        color: #000000;
                    }
                }
            }

            @include respond-to-up('xl') {
                height: 60px;
            }
        }

        textarea {
            resize: none;
            padding: 20px;
            height: auto;
        }

        .choices__inner {
            border: 1px solid #a7a7a7;
            padding: 0 20px !important;
            min-height: 60px;
            display: flex;
            align-items: center;
            background: #F2F2F2;

            .choices__list {
                padding: 0 !important;
            }

            .choices__placeholder {
                font-weight: normal;
                letter-spacing: -0.02em;
                color: #000000;
                opacity: .5;
                font-size: 14px;
            }
        }

        &--pasword {
            position: relative;

            .password-eye {
                display: block;
                fill: #e2e2e2;
                position: absolute;
                right: 25px;
                top: 50%;
                transform: translateY(-40%);
                cursor: pointer;
            }

            input {
                padding: 0 75px 0 20px;
            }
        }
    }

    &__forgetpass {
        display: flex;
        align-items: center;
        gap: 5.5px;

        span {
            font-family: "Open Sans";
            font-weight: normal;
            font-size: 14px;
            letter-spacing: -0.02em;
            color: #3000b2;
        }
    }

    &__create {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #a7a7a7;
        margin-top: 27px;
        padding-top: 27px;

        @include respond-to-up('xl') {
            margin-top: 33px;
            padding-top: 33px;
        }

        &-title {
            font-weight: normal;
            text-align: center;
            color: #272525;
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 19px;

            @include respond-to-up('xl') {

                font-size: 24px;
                line-height: 29px;
            }
        }
    }

    &__checkbox {
        display: flex;
        align-items: center;
        gap: 12px;

        .o-checkbox {
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 10px;

            input {
                display: none;

                &:checked {
                    +.o-checkbox__custom+.o-checkbox__label {
                        color: #333;
                    }

                    +.o-checkbox__custom {
                        background-color: #222;
                        border-color: #222;

                        &::after {
                            display: block;
                        }
                    }
                }
            }

            &__custom {
                width: 20px;
                height: 20px;
                border: 1px solid #272525;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                transition: 0.2s;
                position: relative;

                @include respond-to-up('xl') {
                    width: 26px;
                    height: 26px;
                }

                &::after {
                    content: "✔";
                    font-size: 14px;
                    color: white;
                    display: none;
                    font-weight: bold;
                }
            }

            &__label {
                font-family: "Open Sans";
                font-size: 14px;
                letter-spacing: -0.02em;
                text-align: left;
                color: #a7a7a7;
                cursor: pointer;
                user-select: none;

                @include respond-to-up('xl') {
                    font-size: 16px;
                }

                a {
                    color: #272525;
                    font-size: 14px;
                    letter-spacing: -0.02em;
                    font-weight: bold;

                    @include respond-to-up('xl') {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    &__toggle {
        display: flex;
        align-items: center;

        .o-toggle {
            position: relative;
            display: inline-block;
            width: 69px;
            height: 39px;

            input {
                opacity: 0;
                width: 0;
                height: 0;

                &:checked {
                    +.o-toggle__slider {
                        background-color: #3000B2;

                        &::before {
                            transform: translateX(24px);

                        }
                    }
                }
            }

            &__slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ccc;
                border-radius: 34px;
                transition: 0.4s;

                &::before {
                    position: absolute;
                    content: "";
                    height: 32px;
                    width: 32px;
                    left: 6px;
                    bottom: 3.5px;
                    background-color: white;
                    border-radius: 50%;
                    transition: 0.4s;
                }
            }

            &__label {
                margin-left: 10px;
                font-family: "Open Sans";
                font-weight: normal;
                letter-spacing: -0.02em;
                text-align: center;
                color: #4e4e4e;
                font-size: 14px;

                @include respond-to-up('xl') {
                    font-size: 16px;
                }
            }
        }
    }

    &--login {
        .o-form__forgetpass {
            margin-top: 12px;
        }

        .o-form__checkbox {
            margin-bottom: 20px;
        }
    }
}