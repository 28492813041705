.c-product-detail {
    display: flex;
    gap: 30px;
    flex-direction: column;

    @include respond-to-up('lg') {
        flex-direction: row;
        justify-content: space-between;
    }

    &__slider {
        display: flex;
        flex-direction: column;
        gap: 25px;
        width: 100%;

        @include respond-to-up('lg') {
            width: 450px;
        }

        @include respond-to-up('xxxl') {
            width: 570px;

        }

        .swiper-pagination {
            position: relative;
            margin: 0;
        }

        .swiper-pagination-bullet-active {
            background-color: #000;
        }
    }

    &__main {
        width: 100%;
        border-radius: 20px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 350px;

        @include respond-to-up('lg') {
            height: 450px;
        }

        @include respond-to-up('xxxl') {
            height: 570px;
        }


        .swiper,
        .swiper-wrapper .c-product-detail__slide {
            height: 100%;
        }
    }

    &__slide {

        img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
    }

    &__thumbs {
        width: 100%;

        .swiper-slide-thumb-active {
            .c-product-detail__thumb {
                opacity: 1;
            }
        }


    }

    &__thumb {
        border-radius: 20px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 125px;
        opacity: .6;
        cursor: pointer;

        @include respond-to-up('lg') {
            height: 125px;

        }

        @include respond-to-up('xxxl') {
            height: 150px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }


    }

    &__content {
        @include respond-to-up('lg') {
            padding-top: 40px;
        }
    }

    &__label {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #3000b2;
        width: 69px;
        height: 35px;
        margin-bottom: 15px;

        span {
            font-weight: normal;
            text-align: center;
            font-size: 12px;
            color: #3000b2;
        }

        @include respond-to-up('lg') {
            height: 37px;
            width: 72px;
            margin-bottom: 25px;

            span {
                font-size: 17px;
            }
        }
    }

    &__title {
        font-weight: normal;
        text-align: left;
        color: #a7a7a7;
        line-height: 100%;
        margin-bottom: 10px;
        font-size: 16px;

        @include respond-to-up('lg') {
            font-size: 24px;
        }
    }

    &__price {
        line-height: 100%;
        font-weight: normal;
        text-align: left;
        color: #272525;
        font-size: 40px;
        margin-bottom: 10px;

        @include respond-to-up('lg') {
            font-size: 48px;
            margin-bottom: 20px;
        }
    }

    &__head {
        @include respond-to-up('lg') {
            margin-bottom: 40px;
        }

        p {
            font-family: "Open Sans";
            font-weight: normal;
            letter-spacing: -0.02em;
            text-align: left;
            color: #4e4e4e;
            margin-bottom: 0;
            font-size: 14px;

            @include respond-to-up('lg') {
                font-size: 16px;
            }
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0 0 40px 0;

        @include respond-to-up('lg') {
            flex-direction: row;
        }

        .c-button {
            padding: 0 20px;
            gap: 10px;
        }

        svg {
            width: 24px;
            height: 28px;
        }
    }

    .c-accordion {
        margin-top: 0;

        .c-accordion__content {
            p {
                font-size: 14px !important;
            }
        }
    }
}