.c-404 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &__bg {
        position: absolute;
        inset: 0;
        display: flex;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            inset: 0;
            background-color: rgba(41, 35, 35, 0.616);
        }
    }

    &__content {
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        p {
            font-family: "Open Sans";
            font-weight: normal;
            letter-spacing: -0.02em;
            text-align: center;
            color: #fff;
            margin-bottom: 0;
            font-size: 14px;
            
            @include respond-to-up('xl') {
                font-size: 16px;
            }
        }
    }

    &__title {
        font-weight: normal;
        font-size: 148px;
        line-height: 125px;
        text-align: center;
        color: #fff;
        margin-bottom: 0;
        
        @include respond-to-up('xl') {
            font-size: 202px;
            line-height: 175px;
        }

        &--sm {
            font-family: "Open Sans";
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.02em;
            text-align: center;
            color: #fff;
            
            @include respond-to-up('xl') {
                font-size: 24px;
                line-height: 33px;
            }
        }
    }
}