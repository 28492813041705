.o-page {
    &__content {
        margin-bottom: 30px;

        &--centered {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        >*:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        font-weight: normal;
        text-align: center;
        color: #272525;
        font-size: 32px;
        line-height: 39px;
        
        @include respond-to-up('xl') {
            font-size: 64px;
            line-height: 77px;
        }
    }
}