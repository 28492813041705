.c-profile {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;

    @include respond-to-up('lg') {
        flex-direction: row;
    }

    &__tabs {
        background-color: #FFF;
        border-radius: 20px;
        width: 270px;
        padding: 44px 20px;
        display: flex;
        flex-direction: column;
        gap: 35px;
    }

    &__tab {
        font-size: 16px;
        font-weight: normal;
        line-height: 100%;
        color: #A7A7A7;
        transition: all ease-in-out .2s;

        &:hover, &.active {
            color: #272525;
        }
    }

    &__contents {
        flex: 1;
    }
}