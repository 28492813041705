.c-contact {
    display: flex;
    flex-direction: column;
    gap: 15px;
    
    @include respond-to-up('lg') {
        flex-direction: row;
        gap: 30px;
    }

    &__box {
        border-radius: 40px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 50px 20px;
        
        @include respond-to-up('lg') {
            padding: 0;
            height: 300px;
            flex: 1;
        }
    }

    &__head {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 12px;

        &-title {
            font-weight: normal;
            font-size: 16px;
            letter-spacing: -0.02em;
            text-align: center;
            color: #272525;
            margin-bottom: 0;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;

        p,
        a {
            font-family: "Open Sans";
            font-weight: normal;
            font-size: 16px;
            letter-spacing: -0.02em;
            text-align: center;
            color: #272525;
            margin-bottom: 0;
            text-decoration: none;
        }
    }
}