.c-main-slider {
    height: 100vh;
    margin-bottom: 50px;

    @include respond-to-up('xl') {
        margin-bottom: 75px;
    }

    @include respond-to-up('xxl') {
        margin-bottom: 125px;
    }

    .swiper,
    .swiper-wrapper,
    .swiper-slide,
    &__item {
        height: 100% !important;
    }

    &__image {
        display: flex;
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;

        picture {
            width: 100%;
            height: 100%;
            display: flex;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__content {
        z-index: 1;
        max-width: 950px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 30px;
        position: relative;

        p {
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.02em;
            text-align: center;
            color: #fff8f8;
            max-width: 90%;
            margin-bottom: 10px;
            text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.7);

            @include respond-to-up('md') {
                font-size: 24px;
                max-width: 60%;
                line-height: 36px;
            }


            &:last-child {
                margin-bottom: 0;
            }
        }

        >*:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        font-weight: normal;
        font-size: 26px;
        line-height: 32px;
        text-align: center;
        color: #fff;
        margin-bottom: 8px;
        text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);

        @include respond-to-up('md') {
            font-size: 48px;
            line-height: 54px;
        }

        @include respond-to-up('lg') {
            font-size: 54px;
            line-height: 62px;
            margin-bottom: 15px;
        }

        @include respond-to-up('xxxl') {
            font-size: 64px;
            line-height: 77px;
        }
    }

    &__down {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.2);
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        bottom: 65px;
        padding: 20px;

        @include respond-to-up('xl') {
            bottom: 35px;
            width: 91px;
            height: 91px;
            padding: 0;
        }

        svg {
            animation: bounce 1.5s infinite ease-in-out;
        }
    }

    &__arrow {
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.5);
        transition: all ease-in-out .3s;
        z-index: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        width: 57px;
        height: 57px;

        @include respond-to-up('xl') {
            display: flex;
        }

        svg {
            transition: all ease-in-out .3s;
            fill: $white;
        }

        &:hover {
            cursor: pointer;
            background-color: $white;

            svg {
                fill: #272525;
            }
        }

        &--left {
            left: 40px;
        }

        &--right {
            right: 40px;

            svg {
                transform: rotate(180deg);
            }
        }
    }

    .swiper-pagination {
        z-index: 1;
        position: absolute;
        left: 50%;
        width: auto;
        transform: translateX(-50%);
        bottom: 20px;
        display: flex;

        .swiper-pagination-bullet {
            background: $white !important;
        }

        @include respond-to-up('xl') {
            display: none;
        }
    }
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(5px);
    }
}