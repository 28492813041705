.c-banner {
    width: 100%;
    height: 400px;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    
    @include respond-to-up('xl') {
        margin-top: 120px;
        height: 500px;
    }
    
    @include respond-to-up('xxxl') {
        margin-bottom: 75px;
        height: 645px;
    }

    &__image {
        width: 100%;
        height: 100%;
        
        picture {
            width: 100%;
            height: 100%;
            display: flex;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__content {
        z-index: 1;
        position: absolute;

    }

    &__title {
        font-weight: normal;
        font-size: 40px;
        text-align: center;
        color: #fff;
        margin-bottom: 0;

        @include respond-to-up('xl') {
            font-size: 64px;
        }
    }
}