.o-container {
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;

    @include respond-to-up('xl') {
        max-width: 1230px;
    }
    
    &--md {
        @include respond-to-up('xl') {
            max-width: 830px;
        }
    }

    &--sm {
        @include respond-to-up('xl') {
            max-width: 540px;
        }
    }

}

.o-container-pull-right {
    margin-right: auto;
    max-width: 1920px;
    padding: 0 15px;

    @include respond-to-up('xl') {
        margin-left: calc((100% - 1120px + 40px) / 2);

        padding: 0;
    }

    @include respond-to-up('xxl') {
        margin-left: calc((100% - 1320px + 40px) / 2);
    }

    @include respond-to-up('xxxl') {
        margin-left: calc((100% - 1480px + 40px) / 2);
    }
}