.c-product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    .c-product__item {
        width: 100%;

        @include respond-to-up('md') {
            width: calc(50% - 20px);
        }

        @include respond-to-up('xl') {
            width: calc(100% / 3 - 20px);
        }
    }

}